import request from '@/request/index.js'
import {renderTable_C,insertDate,delDate} from '@/api/baseApi'

//菜单列表
export function menuNaviga() {
    return request({
        method: 'get',
        url: '/menu/menuNaviga'
    })
}
//菜单树
export function menuTree() {
    return request({
        method: 'get',
        url: '/menu/menuTree'
    })
}

//列表接口
export function initTable(params,tableData) {
    renderTable_C('/menu/list',params,tableData)
}

//新增数据
export function insert(data,dialogFormVisible,getData) {
    insertDate( "/menu/save", data.value,dialogFormVisible,getData);
}

//修改数据
export function update(data,dialogFormVisible,getData) {
    insertDate( "/menu/update", data.value,dialogFormVisible,getData);
}

//删除数据
export function del(id,getData) {
    delDate(`/menu/${id}`,getData)
}
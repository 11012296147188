import request from '@/request/index.js'

//列表接口
export function renderTable(url, params, tableData, total) {
    request({
        method: 'get',
        url: url,
        params: params.value
    }).then(data => {
        tableData.value = data.content;
        total.value = data.totalElements;
    }).catch(err => {
        console.log(err)
    })
}
//列表接口
export function renderTable_C(url, params, tableData) {
    request({
        method: 'get',
        url: url,
        params: params === null ? null : params.value
    }).then(data => {
        console.log(data)
        tableData.value = data;
    }).catch(err => {
        console.log(err)
    })
}

//新增数据
export function insertDate(url, data, dialogFormVisible, getDate) {
    request({
        method: 'post',
        url: url,
        data
    }).then(data => {
        //关闭表单
        dialogFormVisible.value = false
        //渲染表格
        getDate();

    }).catch(err => {
        console.log(err)
    });
}


//删除数据
//
export function delDate(url, getDate) {
    request({
        method: 'Delete',
        url: url
    }).then(data => {
        //渲染表格
        getDate();
    }).catch(err => {
        console.log(err);
    })
}
import { menuNaviga } from '@/api/system/menu'

export const loadView = (view) => { // 路由异步懒加载
    return () => import(`@/view/${view}`)
}

const getDefaultState = () => {
    return {
        routes: []
    }
}
const menu = {
    state: {
        routes: []
    },
    mutations: {
        SET_ROUTES: (state, routes) => {
            state.routes = routes
        },
        RESET_STATE: (state) => {
            Object.assign(state, getDefaultState())
        }
    },
    actions: {
        // 生成路由
        GenerateRoutes({ commit }) {
            return new Promise(resolve => {
                menuNaviga().then(data => {
                    //动态路由
                    const menuList = []
                    //处理集合
                    //1、取出一级菜单
                    const oneMenus = data.filter(iteam => iteam.pid === "0")
                    //2、遍历一级数组
                    oneMenus.forEach(e => {
                        //获取子级数组
                        menuList.push(getChildren(data, e))
                    });
                    menuList.push({
                        path: '/',
                        hidden: true,
                        meta: {
                            hiddenBreadcrumb: true,
                        },
                        component: () => import('@/components/Home'),
                        children: [
                            {
                                path: '/:catchAll(.*)',
                                name: '404',
                                hidden: true,
                                component: () => import('@/components/404')
                            },

                        ]
                    })
                    commit('SET_ROUTES', menuList)
                    resolve(menuList)
                }).catch(err => {
                    console.log(err)
                })
            })
        },
        //清除菜单数据
        remove_Data({ commit }) {
            commit('RESET_STATE')
        }
    }
}

export function getChildren(menus, e) {
    //过滤子类
    const childrens = menus.filter(iteam => iteam.pid === e.id)
    const defaultComponent = () => import("@/components/Home")
    const component = loadView(e.component)
    const route = {
        path: e.pid === "0" ? "/home" : e.path,
        name: e.name,
        iconClass: e.icon,
        component: e.pid === "0" ? defaultComponent : component
    }
    const children = []
    childrens.forEach(el => {
        children.push(getChildren(menus, el))
    })
    if (children.length !== 0) {
        route["children"] = children
    }
    return route
}

export default menu

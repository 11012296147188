import { createApp } from 'vue'
import App from './App.vue'
//引入element-plus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import local from  'element-plus/es/locale/lang/zh-cn'

//引入font-awesome图标库
import 'font-awesome/css/font-awesome.min.css'
//引用路由
import router from '@/permission'
//引入vuex
import store from '@/store'
//引入封装的路由
import '@/request/index.js'

const app = createApp(App)
app
.use(router)
.use(ElementPlus,{local})
.use(store)
.mount('#app')

import { createRouter, createWebHistory } from "vue-router";

//静态路由
export const staticRoutes = [
    {
        path: '/',
        redirect: '/home',
        hidden: true,
        component: () => import('@/components/main')
    },
    {
        path: '/home', 
        hidden: true,
        component: () => import('@/components/main')
    },
    {
        path: '/info', 
        hidden: true,
        component: () => import('@/components/idmeoInfo')
    },
    {
        path: '/:catchAll(.*)',
        name: '404',
        hidden: true,
        component: () => import('@/components/404')
    },
    // {
    //     path: '/home',
    //     meta: {
    //         hiddenBreadcrumb: true,
    //     },
    //     redirect: '/home/index',
    //     // component: resolve => require(['@/components/Home'],resolve) //异步组件
    //     component: () => import('@/components/Home'), //懒加载
    //     children: [
    //         {
    //             path: '/home/index',
    //             meta: {
    //                 hiddenBreadcrumb: true,
    //             },
    //             component: () => import('@/components/index') //懒加载
    //         },

    //     ]
    // },


];

export default createRouter({
    history: createWebHistory(),
    routes: staticRoutes,
})


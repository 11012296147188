import { createStore } from 'vuex'
import menu from '@/store/modules/menu'


const store = createStore({
  modules: {
    menu,
  }
})

export default store
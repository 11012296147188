import { getToken } from "@/utils/tokenUtils";
import router from "@/router";
import store from "@/store";
// 导航守卫
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {
    next();
    // let token = getToken('token');
    // //是否为登录页面
    // if (to.path === '/login') {
    //     //如果已经登录重定向到首页
    //     if (token) {
    //         next('/home');
    //     } else {
    //         next();
    //     }
    // } else {
    //     if (token === null || token === '') {
    //         next('/home');
    //     } else {
    //         //加载权限
    //         // if (store.state.menu.routes.length === 0) {
    //         //     // 获取可访问的路由数据
    //         //     store.dispatch('GenerateRoutes').then(accessRoutes => {
    //         //         // 添加可访问的路由表
    //         //         accessRoutes.forEach(iteam => {
    //         //             router.addRoute(iteam);
    //         //         })
    //         //         // hack方法 确保addRoutes已完成
    //         //         next({ ...to, replace: true });
    //         //         return;
    //         //     })
    //         // } else {
    //         //     next();
    //         // }

    //     }
    // }
});

export default router
import axios from "axios";
import { getToken, removeToken } from "@/utils/tokenUtils";
import { ElMessage,ElMessageBox } from "element-plus";
const service = axios.create({
    baseURL: '/api',
    timeout: 10000
})

//添加拦截器
service.interceptors.request.use((config) => {
    //请求前封装
    config.headers['token'] = "Bearer " + getToken('token')
    return config
}, (error) => {
    return Promise.reject(error)
})

//添加响应拦截器
service.interceptors.response.use((response) => {
    //响应数据处理
    let { code, data, message } = response.data
    if (code !== 200) {
        if (code === 401) {
            //跳转到无授权页面
            console.log("跳转页面")
            ElMessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', { confirmButtonText: '重新登录', cancelButtonText: '取消', type: 'warning' }).then(() => {
                removeToken("token")
                removeToken("userName")
                location.href = '/login';
            }).catch(() => {
            });
        } else if (code === 500) {

        }
        ElMessage({ message: message || 'error', type: 'warning' })
        return Promise.reject(message)
    }
    return data
}, (error) => {
    let { message } = error;
    console.log(message)
    console.log(error)
    if (message == "Network Error") {
        message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
        message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
        message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    ElMessage({ message: message, type: 'error', duration: 5 * 1000 })
    return Promise.reject(error)
})

export default service
